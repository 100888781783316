

.card {
  width: 100%;
  height: 100vh;
  margin: auto;
  min-height: 512px;
  background-color: rgb(203, 202, 201);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 512px) {
  .card {
    width: 100%;
    height: 100vh;
    margin: auto;
    min-height: 512px;
    background-color: rgb(203, 202, 201);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .logo img{
    width: 100%;
    margin-top: 20%;
  }
  
}


.logo {
  font-weight: bold;
  font-size: 1.5em;
  transition: opacity 0.05s ease;
}

.logo:hover {
  opacity: 0.75;
}

.social {
  display: flex;
  flex-direction: column;
}

.social a {
  display: inline-block;
  margin-right: 12px;
  transition: opacity 0.05s ease;
}

.social a:last-child {
  margin-right: 0;
}

.social a:hover {
  opacity: 0.75;
}

.social .icon {
  width: 18px;
  fill: #fff;
}

.content {
  flex: 1 1 auto;
  min-height: 256px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content .title-holder {
  
  text-align: center;
  margin-bottom: 24px;
}

.content .title-holder h1 {
  font-weight: bold;
  font-size: 36px;
  color:black;
  margin-bottom: 12px;
}

.content .title-holder p {
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  color: #8c7c64;
}

@media screen and (max-width: 768px) {
  .content .title-holder {
    max-width: 80%;
    max-height:80vh;
  }
  .logo {
    font-size: 1.2em; /* Decrease font size for smaller screens */
  }

}

.content .cta {
  min-width: 64px;
  padding: 16px 24px;
  background: #fff;
  border-radius: 50px;
  cursor: pointer;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  transform: none;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.25, 0.315, 1.35), transform 0.1s linear;
}

.content .cta:hover {
  transform: translateY(-1px);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.3);
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 48px;
  justify-content: center;
  color: #8c7c64;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

a.underlined:hover {
  border-bottom: 1px dotted #fff;
}